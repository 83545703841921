@use "@angular/material" as mat;
// @import "@angular/material/theming";
@import "./theme.scss";

@include mat.all-component-typographies();
@include mat.core();
@include mat.all-component-typographies($typography);
@include mat.all-component-themes($theme);

@font-face {
  font-family: nunito;
  src: url(assets/fonts/nunito.ttf) format("opentype");
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

mat-form-field {
  width: 100%;
}

.dialog-no-padding mat-dialog-container {
  padding: 0;
}

.link {
  cursor: pointer;
  font-weight: 600;
  color: #4d65ea;
}

// Input Number
* {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    appearance: none;
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield;
  }
}

// Maps
// * {
//   .map-center > div {
//     margin: auto;
//   }
//   .gm-style .gm-style-iw-d::-webkit-scrollbar-track,
//   .gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece,
//   .gm-style .gm-style-iw-c,
//   .gm-style .gm-style-iw-t::after {
//     background: #252525 !important;
//   }
// }
// Maps

// Tablas
* {
  mat-cell {
    padding: 3px;
    span {
      overflow: hidden;
    }
  }

  .no-info {
    font-style: italic;
    color: gray;
    overflow: visible;
  }
}
// Tablas

// SnackBar
* {
  .red-snackbar {
    background-color: #f44336;
    color: white;
  }
  .green-snackbar {
    background-color: #4caf50;
    color: white;
  }
  .yellow-snackbar {
    background-color: #ffeb3b;
    color: black;
  }
}
// Scroll Bar
* {
  ::-webkit-scrollbar {
    width: 22px;
    // background-color: #f5f5f5;
  }

  ::-webkit-scrollbar-track {
    margin-left: 2px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    // background-color: #f5f5f5;
    background-clip: content-box;
    border: 5px solid transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: $primary;
    background-clip: content-box;
    border: 5px solid transparent;
  }
}

.gm-style .gm-style-iw-c {
  padding: 0;
  margin: 0.5em;
  overflow: hidden;
}

.mat-mdc-form-field-subscript-wrapper {
  display: none;
}
