@use "@angular/material" as mat;

//
// Paletas de colores
//

$primary-palette-colors: (
  50: #e2e7ed,
  100: #b8c3d1,
  200: #889bb2,
  300: #587293,
  400: #35547c,
  500: #113665,
  600: #0f305d,
  700: #0c2953,
  800: #0a2249,
  900: #051637,
  A100: #6f94ff,
  A200: #3c6eff,
  A400: #0948ff,
  A700: #003cef,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);
$accent-palette-colors: (
  50: #edfdf5,
  100: #d2fbe7,
  200: #b4f8d7,
  300: #96f5c6,
  400: #80f2ba,
  500: #69f0ae,
  600: #61eea7,
  700: #56ec9d,
  800: #4ce994,
  900: #3be584,
  A100: #ffffff,
  A200: #fdfffe,
  A400: #caffe0,
  A700: #b1ffd1,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
$warn-palette-colors: (
  50: #fee8e7,
  100: #fcc7c3,
  200: #faa19b,
  300: #f77b72,
  400: #f65f54,
  500: #f44336,
  600: #f33d30,
  700: #f13429,
  800: #ef2c22,
  900: #ec1e16,
  A100: #ffffff,
  A200: #ffe9e9,
  A400: #ffb8b6,
  A700: #ff9f9c,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

//
// Typography
//

// H1
$headline-config: mat.m2-define-typography-level(
  $font-family: Roboto,
  $font-weight: 900,
  $font-size: 2rem,
  $line-height: 1,
  $letter-spacing: normal,
);
// H2
$title-config: mat.m2-define-typography-level(
  $font-family: Roboto,
  $font-weight: 700,
  $font-size: 1.5rem,
  $line-height: 1,
  $letter-spacing: normal,
);
// H3
$subheading-2-config: mat.m2-define-typography-level(
  $font-family: Roboto,
  $font-weight: 600,
  $font-size: 1rem,
  $line-height: 1,
  $letter-spacing: normal,
);
// H4
$subheading-1-config: mat.m2-define-typography-level(
  $font-family: Roboto,
  $font-weight: 500,
  $font-size: 0.75rem,
  $line-height: 1,
  $letter-spacing: normal,
);
// Body
$body-1-config: mat.m2-define-typography-level(
  $font-family: Roboto,
  $font-weight: 500,
  $font-size: 1rem,
  $line-height: 1,
  $letter-spacing: normal,
);
// Body Bold
$body-2-config: mat.m2-define-typography-level(
  $font-family: Roboto,
  $font-weight: 700,
  $font-size: 1rem,
  $line-height: 1,
  $letter-spacing: normal,
);
// Body small
$caption-config: mat.m2-define-typography-level(
  $font-family: Roboto,
  $font-weight: 500,
  $font-size: 0.75rem,
  $line-height: 1,
  $letter-spacing: normal,
);
// Button
$button-config: mat.m2-define-typography-level(
  $font-family: Roboto,
  $font-weight: 500,
  $font-size: 1rem,
  $line-height: 1,
  $letter-spacing: normal,
);
// Input
$input-config: mat.m2-define-typography-level(
  $font-family: Roboto,
  $font-weight: 500,
  $font-size: 1rem,
  $line-height: 1,
  $letter-spacing: normal,
);

$typography: mat.m2-define-legacy-typography-config(
  $font-family: Roboto,
  $display-4: $headline-config,
  $display-3: $headline-config,
  $display-2: $headline-config,
  $display-1: $headline-config,
  $headline: $headline-config,
  $title: $title-config,
  $subheading-2: $subheading-2-config,
  $subheading-1: $subheading-1-config,
  $body-1: $body-1-config,
  $body-2: $body-2-config,
  $caption: $caption-config,
  $button: $button-config,
  $input: $input-config,
);

$primary-palette: mat.m2-define-palette($primary-palette-colors);
$accent-palette: mat.m2-define-palette($accent-palette-colors);
$warn-palette: mat.m2-define-palette($warn-palette-colors);
$theme: mat.m2-define-dark-theme(
  (
    color: (
      primary: $primary-palette,
      accent: $accent-palette,
      warn: $warn-palette,
    ),
  )
);

// Colores

$background: #fdfffc;

$primary200: mat.m2-get-color-from-palette($primary-palette, "200");
$primary300: mat.m2-get-color-from-palette($primary-palette, "300");
$primary: mat.m2-get-color-from-palette($primary-palette, "default");
$primaryContrast: mat.m2-get-color-from-palette(
  $primary-palette,
  "default-contrast"
);
$primary900: mat.m2-get-color-from-palette($primary-palette, "darker");

$accent: mat.m2-get-color-from-palette($accent-palette, "default");
$accentContrast: mat.m2-get-color-from-palette(
  $accent-palette,
  "default-contrast"
);

$warn: mat.m2-get-color-from-palette($warn-palette, "default");
$warnContrast: mat.m2-get-color-from-palette($warn-palette, "default-contrast");
